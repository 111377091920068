<script setup lang="ts">
useHead({
    bodyAttrs: {
        class: 'bg-white'
    },
    htmlAttrs: {
        class: 'bg-white'
    }
})
</script>
<template>
    <slot />
</template>